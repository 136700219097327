import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const imageStyle = {
	borderRadius: "50%",
};

export const profile = [
	{
		name: "Albert Yim",
		title: "Software Engineer",
		img: (
			<StaticImage
				loading="eager"
				imgStyle={imageStyle}
				src="./ayim.png"
				alt="albert yim"
			/>
		),
	},
	{
		name: "Alex Chang",
		title: "Advisor",
		img: (
			<StaticImage
				loading="eager"
				imgStyle={imageStyle}
				src="./achang.png"
				alt="alex chang"
			/>
		),
	},
	{
		name: "Corianton Johnson",
		title: "Account Executive",
		img: (
			<StaticImage
				loading="eager"
				imgStyle={imageStyle}
				src="./cjohnson.png"
				alt="corianton johnson"
			/>
		),
	},
	{
		name: "Dan Tu",
		title: "Software Engineer",
		img: (
			<StaticImage
				loading="eager"
				imgStyle={imageStyle}
				src="./dtu.png"
				alt="dan tu"
			/>
		),
	},
	{
		name: "Darrick Carter",
		title: "Advisor",
		img: (
			<StaticImage
				loading="eager"
				imgStyle={imageStyle}
				src="./dcarter.png"
				alt="darrick carter"
			/>
		),
	},
	{
		name: "Felix Livni",
		title: "Advisor",
		img: (
			<StaticImage
				loading="eager"
				imgStyle={imageStyle}
				src="./flivni.png"
				alt="Felix Livni"
			/>
		),
	},
	{
		name: "Isaac Kato",
		title: "Advisor",
		img: (
			<StaticImage
				loading="eager"
				imgStyle={imageStyle}
				src="./ikato.png"
				alt="isaac kato"
			/>
		),
	},
	{
		name: "Jacob Lee",
		title: "CEO & Co-Founder",
		img: (
			<StaticImage
				loading="eager"
				imgStyle={imageStyle}
				src="./jlee.png"
				alt="jacob lee"
			/>
		),
	},
	{
		name: "Jeanne Chowning",
		title: "Advisor",
		img: (
			<StaticImage
				loading="eager"
				imgStyle={imageStyle}
				src="./jchowning.png"
				alt="jeanne chowning"
			/>
		),
	},
	{
		name: "Jeremy Lawrence",
		title: "Sr. Software Engineer",
		img: (
			<StaticImage
				loading="eager"
				imgStyle={imageStyle}
				src="./jlawrence.jpg"
				alt="jeremy lawrence"
			/>
		),
	},
	{
		name: "Jin Choe",
		title: "CTO & Co-Founder",
		img: (
			<StaticImage
				loading="eager"
				imgStyle={imageStyle}
				src="./jchoe.png"
				alt="jin choe"
			/>
		),
	},
	{
		name: "Lucinda Liu",
		title: "Product Manager",
		img: (
			<StaticImage
				loading="eager"
				imgStyle={imageStyle}
				src="./lliu.png"
				alt="lucinda liu"
			/>
		),
	},
	{
		name: "Muhammad Hariz",
		title: "Software Engineer",
		img: (
			<StaticImage
				loading="eager"
				imgStyle={imageStyle}
				src="./mhariz.jpg"
				alt="muhammad hariz"
			/>
		),
	},
	{
		name: "Paul Miranda",
		title: "Software Engineer",
		img: (
			<StaticImage
				loading="eager"
				imgStyle={imageStyle}
				src="./pmiranda.png"
				alt="paul miranda"
			/>
		),
	},
	{
		name: "Paul Moran",
		title: "CFO",
		img: (
			<StaticImage
				loading="eager"
				imgStyle={imageStyle}
				src="./pmoran.png"
				alt="paul moran"
			/>
		),
	},
	{
		name: "Tina Lu",
		title: "Product Designer",
		img: (
			<StaticImage
				loading="eager"
				imgStyle={imageStyle}
				src="./tlu.png"
				alt="tina lu"
			/>
		),
	},
	{
		name: "Yuqi Feng",
		title: "UX Engineer",
		img: (
			<StaticImage
				loading="eager"
				imgStyle={imageStyle}
				src="./yfeng.png"
				alt="yuqi feng"
			/>
		),
	},
];
