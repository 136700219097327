// extracted by mini-css-extract-plugin
export var arrowBtn = "index-module--arrowBtn--Zcpwr";
export var backedByLogos = "index-module--backedByLogos--QcPXz";
export var but = "index-module--but--WP53V";
export var centerContent = "index-module--centerContent--B2Wt2";
export var commonTopBottomMargins = "index-module--commonTopBottomMargins--QVz9s";
export var coreValues = "index-module--coreValues--VVwQF";
export var coreValuesImage = "index-module--coreValuesImage--CstgX";
export var coreValuesImageMobile = "index-module--coreValuesImageMobile--zBtjZ";
export var desc = "index-module--desc--AtIvl";
export var flexCenter = "index-module--flexCenter--xJMBh";
export var flexColumn = "index-module--flexColumn--YblgK";
export var groupingLines = "index-module--groupingLines--L9qB3";
export var hero = "index-module--hero--8zjko";
export var heroHeader = "index-module--heroHeader--xZ5Ux";
export var investor = "index-module--investor--eFvwN";
export var limitWidthOnLargeScreens = "index-module--limitWidthOnLargeScreens--ybOs4";
export var lineWithBump = "index-module--lineWithBump--r0VlM";
export var meetOurTeam = "index-module--meetOurTeam--HwTZ+";
export var people = "index-module--people--vavA4";
export var personCard = "index-module--personCard--OKsRU";
export var personCardImage = "index-module--personCardImage--6W0Ny";
export var pink = "index-module--pink--bgTJE";
export var singleLine = "index-module--singleLine--kg8Vd";
export var vision = "index-module--vision--E+5M-";
export var visionCard = "index-module--visionCard--zG7vg";
export var visionCards = "index-module--visionCards--z4Bo-";
export var visionContent = "index-module--visionContent--g3q-c";
export var visionPart2Background = "index-module--visionPart2Background--HMPJB";